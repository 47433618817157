<template>
  <div class="user-center-wrapper" :class="type">
    <div class="user-info-con">
      <div class="user-info-item">
        <img class="user-thumb" :src="userThumb || userThumbDefault"/>
        <div class="user-name">
          <p>{{ userName }}</p>
        </div>
      </div>
      <div class="img mob"></div>
    </div>
    <!-- 组件 -->
    <div class="user-center" v-if="!applySuccess">
      <!-- 侧边菜单栏 -->
      <div class="sider">
        <div class="tabs-wrapper">
          <div class="tabs" :class="'selected' + selectedTab">
            <div
              class="tabs-pane"
              :class="selectedTab === index ? 'selected' : ''"
              v-for="(item, index) in tabsList"
              :key="'user_center_tab_'+index"
              @click="toggleTabs(index)"
            >
              <svg class="icon icon-left" aria-hidden="true">
                <use :xlink:href="'#icon-' + item.iconName"></use>
              </svg>
              <p>{{ item.text }}</p>
              <svg class="icon icon-right" aria-hidden="true">
                <use xlink:href="#icon-right"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <!-- 组件--门票/订单/发票/设置 -->
      <div class="content-wrapper">
        <div class="user-content">
          <MyTicket
            v-if="selectedTab == '0'"
            :token="token"
            :sid="sid"
            :type="type"
          ></MyTicket>
          <MyOrder
            v-else-if="selectedTab == '1' && !showInvoice"
            :token="token"
            :sid="sid"
            :type="type"
            @openInvoice="openInvoice"
          ></MyOrder>
          <OpenInvoice
            v-else-if="selectedTab == '1' && showInvoice"
            :token="token"
            :sid="sid"
            :type="type"
            :userName="userName"
            :userPhone="userPhone"
            @applyInvoiceSuccess="applyInvoiceSuccess"
            @openOrder="openOrder"
          ></OpenInvoice>
          <Settings
            v-if="selectedTab == '2'"
            :token="token"
            :sid="sid"
          ></Settings>
        </div>
        <div class="invoice-warn" v-show="showInvoice">
          <h4>开票须知</h4>
          <p>
            1、提供付款方的发票抬头，纳税人识别号，用于开发票（普票为电子发票）
          </p>
          <p>2、如有疑问请咨询业务电话：{{global.companyphone}}</p>
        </div>
      </div>
    </div>
    <!-- 发票提交成功 -->
    <div v-else class="invoice-success">
      <div>
        <img src="https://f.msup.com.cn/common_apply_success.png" />
        <h4>提交成功!</h4>
      </div>
      <div class="invoice-success-con">
        <p><span>账户名称：</span>麦思博(北京)软件技术有限公司</p>
        <p><span>帐号：</span>912401 54800002818</p>
        <p><span>您还可以：</span><a @click.prevent="toHome()">返回首页</a></p>
      </div>
    </div>
    <div class="user-center-mob">
      <div class="my-ticket">
        <!-- <h2>我的门票信息</h2> -->
      </div>
      <div class="active-ticket"></div>
    </div>
  </div>
</template>


<script>
import { getUserInfo } from "@/utils/api.js";
import MyTicket from "@/components/center/MyTicket.vue";
import MyOrder from "@/components/center/MyOrder.vue";
import OpenInvoice from "@/components/center/OpenInvoice.vue";
import Settings from "@/components/center/Settings.vue";

export default {
  name: "UserCenter",
  components: {
    MyTicket,
    MyOrder,
    OpenInvoice,
    Settings,
  },
  data() {
    return {
      type: "giac", // 会议类型
      sid: "",
      selectedTab: 0, // 0 我的门票、1我的订单、2个人设置、3开具发票
      tabsList: [
        {
          text: "我的门票",
          iconName: "ticket",
        },
        {
          text: "我的订单",
          iconName: "record",
        },
        {
          text: "个人设置",
          iconName: "settings",
        },
      ],
      userName: "",
      userPhone: "",
      showInvoice: false,
      applySuccess: false,
      userThumb: "",
      userThumbDefault: 'https://f.msup.com.cn/center-default-user.png',
    };
  },
  created() {
    this.getTokenAndQuery();
  },
  mounted() {
    this.token = window.localStorage.getItem("token");
    if (!this.token || this.token === null) {
      localStorage.setItem("linkurl", window.location.href);
      this.$router.push({
        path: `/${this.global.year}/login`,
      });
      return;
    }
    this.getUserInfo();
  },
  watch: {
    $route: {
      handler(to) {
        this.selectedTab = to.query.selected;
        this.showInvoice = false;
      },
    },
  },
  methods: {
    toHome() {
      this.$router.push({ path: `/${this.global.year}/home` });
    },
    applyInvoiceSuccess() {
      this.applySuccess = true;
    },
    openInvoice() {
      // 切换到开具发票的部分
      this.showInvoice = false;
      this.showInvoice = true;
      this.$forceUpdate();
    },
    openOrder() {
      // 切换到订单的部分
      this.showInvoice = false;
      this.$forceUpdate();
    },
    getTokenAndQuery() {
      if (this.$route.query.selected) {
        this.selectedTab = parseInt(this.$route.query.selected);
      }
      this.token = window.localStorage.getItem("token");
      if (!this.token || this.token === null) {
        // 保存当前链接，跳转登录
        this.$router.push({ path: `/${this.global.year}/login` });
        return;
      }
    },
    toggleTabs(index) {
      this.selectedTab = index;
      this.showInvoice = false;
      this.$forceUpdate();
    },
    // 用户信息
    getUserInfo() {
      this.$http
        .jsonp(`${getUserInfo}?token=${this.token}`)
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          console.log("getUserInfo ", data);
          if (data.status === "200") {
            this.userName = data.data.name;
            this.userPhone = data.data.phone;
            if (data.data.thumbs && data.data.thumbs != "") {
              this.userThumb = JSON.parse(data.data.thumbs)[0].fileUrl;
            }
            this.name = data.data.name;
            this.phone = data.data.phone;
            this.company = data.data.company;
            this.position = data.data.position;
            this.department = data.data.department;
            this.email = data.data.email;
          } else if (data.code === "1001") {
            window.alert("当前登录状态已过期，请重新登录");
            window.localStorage.removeItem("token");
            this.$router.push({ path: `/${this.global.year}/login` });
          } else {
            console.log("获取用户信息失败");
          }
        });
    },
    // 复制链接
    copyUrl(item) {
      window.getSelection().removeAllRanges();
      let copyDOM = document.getElementById(`${item.number}`);
      let range = document.createRange();
      range.selectNode(copyDOM);
      window.getSelection().addRange(range);
      try {
        // let successful = document.execCommand("copy");
        window.alert("复制成功", 800);
      } catch (e) {
        window.alert("复制失败");
      }
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: var(--main-color);
  color: #fff;
}
</style>
<style lang="scss" scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
}
.icon-left {
  color: #9e9e9e;
}
.icon-right {
  color: #eeeeee;
}

.giac {
  --main-color: #003177;
}

.user-center-wrapper {
  min-height: 100vh;
  background-color: #f8f8f8;
}

/* 用户信息 */
.user-info-con {
  width: 100%;
  height: 148px;
  text-align: center;
  margin: 0 auto;
  background-color: var(--main-color);
  position: relative;
  overflow: hidden;
}
.user-info-item {
  width: 100%;
  max-width: 1200px;
  padding: 34px 15px 0 15px;
  text-align: left;
  position: absolute;
  z-index: 2;
  display: flex;
  .user-thumb {
    border: 1px solid #ffffff;
    padding: 3px;
  }
  .user-name {
    padding-left: 14px;
    p {
      font-size: 21px;
      font-weight: 500;
      color: #fff;
      padding: 15px 0;
      margin: 0;
      cursor: pointer;
    }
  }
}
@media (min-width: 1200px) {
  .user-info-item {
    left: 50%;
    margin-left: -600px;
  }
}
.user-info-item > img {
  width: 90px;
  height: 90px;
  border-radius: 50px;
}

/* 信息中心 */
.user-center p {
  margin-bottom: 0;
}
.user-center {
  max-width: 1200px;
  margin: 25px auto 0;
  display: flex;
  padding-bottom: 50px;
}

/* 信息中心-左 */
.sider {
  flex-shrink: 0;
  display: inline-block;
  width: 20%;
  max-height: 186px;
  margin-right: 23px;
  background: #fff;
  .tabs-wrapper {
    .tabs {
      position: relative;
      .tabs-pane {
        border-top: 2px solid #f8f8f8;
        padding: 18px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        color: #333;
        * {
          cursor: pointer;
        }
        .icon {
          font-size: 20px;
        }
        p {
          margin-left: 16px;
          margin-right: auto;
        }

        &.selected {
          color: var(--main-color);
          border-left-color: var(--main-color);
          .icon-left,
          .icon-right {
            color: var(--main-color);
          }
        }
      }
      &::before {
        content: "";
        width: 6px;
        height: 62px;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--main-color);
        transition: all linear 0.3s;
      }
      &.selected0::before {
        top: 0;
      }
      &.selected1::before {
        top: 62px;
      }
      &.selected2::before {
        top: 124px;
      }
    }
  }
}

.sider .tabs-wrapper {
  margin-bottom: 300px;
}
/* 信息中心-右 */
.content-wrapper {
  width: 100%;
}
.user-content {
  width: 936px;
  min-height: 500px;
  background: rgba(255, 255, 255, 1);
}
.invoice-warn {
  width: 936px;
  padding: 35px 34px 40px;
  border: none;
  background-color: #f8f8f8;
  text-align: left;
  h4 {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    border: none;
    margin-top: 34px;
    padding-bottom: 11px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    color: #777;
  }
}
/* 发票提交成功 */
.invoice-success {
  width: 100%;
  padding: 70px 30px;
  text-align: center;
  background-color: #ffffff;
  div:not(.invoice-success-con) {
    width: fit-content;
    margin: 0 auto;
    display: flex;
  }
  img {
    width: 80px;
    height: 80px;
    padding: 9px 9px 5px 5px;
    border-radius: 50px;
    background-color: #1bbb99;
  }
  h4 {
    font-size: 20px;
    font-weight: 400;
    line-height: 80px;
    padding-left: 30px;
    color: #444444;
  }
}
.invoice-success-con {
  width: fit-content;
  text-align: left;
  margin: 0 auto;
  margin-top: 60px;
  p {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    margin-bottom: 15px;
    span {
      color: #444444;
    }
    a {
      color: var(--main-color);
      cursor: pointer;
    }
  }
}

@media (max-width: 767px) {
  .user-center-wrapper {
    width: 100vw;
    .user-center {
      margin: 0;
      padding: 0;
      width: 100%;
      .sider {
        display: none;
      }
      .user-content {
        width: 100%;
      }
      .invoice-warn {
        width: 100%;
        p {
          margin-top: 4px;
        }
      }
    }
    .invoice-success {
      padding: 10vh 0;
    }
  }
}
</style>
